/*
This is where you write custom SASS
*/
.form-group {
    input,textarea {
        background: #EAEEF0 !important;
    }
}
.form-group {
    input#copyClipboard {
        background: $primary !important;
    }
}

.btn {
    border-radius: 100px;
}

section.map-section {
    @media screen and (max-width: 767px) { 
        [style*="top: -45px"] {
            top: -80px !important;
        }
        .iframe-wrap {
            height: 850px!important;
            iframe {
                margin: -175px 0px !important;
            }
        }
    }
    a:hover {
        cursor: pointer;
    }
    .iframe-wrap {
        height: 650px;
        iframe {
            height: 100% !important;
            margin: -125px 0px !important;
        }
    }
    
    .iframe-container {
        .iframe-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            visibility: hidden;
            opacity: 0;
            transition: 0.4s ease 0s, transform 0.4s ease 0.4s;
            transform: scale(1.2);
            &.active {
                visibility: visible;
                opacity: 1;
                transition: 0.4s ease 0s;
                transform: scale(1);
            }
        }
    }
    a.active {
        color: #84A202 !important;
    }

}

section.home-carousel {
    @media screen and (max-width: 1500px) {
        [style*="height: 795px;"] {
            height: 650px !important;
        }
    }
    
    @media screen and (max-width: 1199px) {
        [style*="height: 795px;"] {
            height: 550px !important;
        }
    }
    @media screen and (max-width: 767px) {
        [style*="height: 795px;"] {
            height: 450px !important;
        }
    }
}

section.testimonials {
    .owl-dots  {
        bottom: 30px !important;
    }
    blockquote {
        letter-spacing: 0.02em;
        cite {
            letter-spacing: 0.08em;
        }
    }
    .owl-stage-outer {
        border-radius: 50%;
        .owl-item {
            // max-width: 570px !important;
            position: relative;
            blockquote {
                width: 70%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%,-50%,0);
                margin-bottom: 0px !important;

            }
        }
    }
}

section.contact-section {
    .form-group {
        input, textarea {
            border: none !important;
        }
        margin-top: 0px !important;
        margin-bottom: 15px !important;
    }
    [id*="btnSend"] {
        max-width: 171px;
    }
    .number-bar {
        bottom: -40px;
        left: 0;
        z-index: 1;
    }
    @media screen and (max-width: 991px) {
        .number-bar {
            bottom: 0;
        }
    }
}

footer {
    a {
        color: $white!important; 
        &:hover {
            color: #d9d9d9!important; 
        }
    }
}

body.interior {
    .contact-form .card {
        padding: 50px; 
        background: #EAEEF0;
        p.h3 {
            color: #A7C526;
        }
        label {
            display: none;

        }
        input,textarea {
            border: none;

        }
        .form-control { 
            background: #fff !important;
            border-radius: 5px !important;
        }
    }
}

@media screen and (min-width: 992px) {

    section.services-section .testimonials .owl-stage {
        width: 100% !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: none !important;
    position: relative;
    height: 100%;
}
section.services-section .testimonials .owl-stage .owl-item {
    position: absolute;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0;
    transition: 0.4s ease;
}
section.services-section .testimonials .owl-stage .owl-item.active {
    opacity: 1;
}
section.services-section .testimonials .owl-stage .owl-item blockquote p {
    margin: 0 0 10px 0 !important;
}
}

@media screen and (max-width: 1800px) {
    section.services-section .testimonials .owl-stage .owl-item blockquote p {
        font-size: 20px !important;
        line-height: 30px !important;
    }
}

